import {
  DisplayWhenTypes,
  IMobileNavBaseItem,
} from '@/src/common/components/elements/Layout/Navigation/types'
import ContactUsText from '@/src/common/components/elements/Text/ContactUsText'
import { getBaseDomain } from '@/src/common/utilities/whiteLabel'
import { signOut } from '@/src/modules/login/FirebaseAuth'

export const whiteLabelConsumerMobileNavMenuData = (checkBalanceUrl?: string): IMobileNavBaseItem[] => {
  return [
      {
          label: 'Purchase',
          url: `/purchase`,
          testId: 'mobile-brands',
      },
      {
          label: '24/7 Help',
          testId: 'mobile-help-section',
          subSection: [
              {
                  label: '',
                  testId: 'mobile-help-inner',
                  children: [
                      {
                          label: 'Visit our help center',
                          gradientText: 'help center',
                          url: 'https://perfectgiftsupport.zendesk.com/hc/en-us',
                          testId: 'mobile-help-center',
                          icon: 'fa-square-question',
                      },
                      {
                          label: 'Check balance',
                          gradientText: 'balance',
                          url: checkBalanceUrl ?? '/check-balance',
                          testId: 'mobile-check-balance',
                          icon: 'fa-receipt',
                      },
                      {
                          label: 'Track your order',
                          gradientText: 'Track',
                          url: '/order-status',
                          testId: 'mobile-track',
                          icon: 'fa-truck-fast',
                      },
                  ],
                  formattedContent: ContactUsText(),
              },
          ],
      },
      {
          label: 'Log In',
          displayWhen: DisplayWhenTypes.LOGGED_OUT,
          testId: 'mobile-login-section',
          subSection: [
              {
                  label: 'For individuals',
                  testId: 'mobile-individuals',
                  children: [
                      {
                          label: 'Log in to PerfectGift',
                          url: '/login',
                          testId: 'mobile-login-consumer',
                          icon: 'fa-gift',
                      },
                  ],
              },
              {
                  label: 'For business',
                  testId: 'mobile-business',
                  children: [
                      {
                          label: 'Log in to Corporate Portal',
                          url: 'https://corp.perfectgift.com/login',
                          testId: 'mobile-corp-external',
                          icon: 'fa-briefcase',
                      },
                      {
                          label: 'Request a corporate account',
                          url: `${getBaseDomain()}/corporate/get-started`,
                          testId: 'mobile-request-corp',
                          icon: 'fa-circle-plus',
                      },
                  ],
              },
          ],
      },
      {
          label: 'My Account',
          displayWhen: DisplayWhenTypes.LOGGED_IN,
          testId: 'mobile-login-section',
          subSection: [
              {
                  label: 'For individuals',
                  testId: 'mobile-individuals',
                  children: [
                      {
                          label: 'Sign Out',
                          clickAction: () => signOut(),
                          testId: 'mobile-login-consumer',
                          icon: 'fa-right-to-bracket',
                      },
                      {
                          label: 'Dashboard',
                          url: '/account',
                          testId: 'dt-corp',
                          icon: 'fa-home',
                      },
                      {
                          label: 'Received Gifts',
                          url: '/account/gifts/received',
                          testId: 'dt-corp',
                          icon: 'fa-gift',
                      },
                      {
                          label: 'Order History',
                          url: '/account/orders',
                          testId: 'dt-order-history',
                          icon: 'fa-receipt',
                      },
                      {
                          label: 'Manage Account',
                          url: '/account/manage-account',
                          testId: 'dt-corp',
                          icon: 'fa-user',
                      },
                  ],
              },
              {
                  label: 'For business',
                  testId: 'mobile-business',
                  children: [
                      {
                          label: 'Log in to Corporate Portal',
                          url: 'https://corp.perfectgift.com/login',
                          testId: 'mobile-corp-external',
                          icon: 'fa-briefcase',
                      },
                      {
                          label: 'Request a corporate account',
                          url: `${getBaseDomain()}/corporate/get-started`,
                          testId: 'mobile-request-corp',
                          icon: 'fa-circle-plus',
                      },
                  ],
              },
          ],
      },
  ]
}